.wrapper {
  position: absolute;
  //   top: 0;
  //   left: 0%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--darkest);
  // background-image: url(../../public/grid_deep.svg);
  //   background-size: 300%;
  background-size: cover;

  //   filter: grayscale(100%);
}

.wrapperBlog {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0e0e0e;
  color: #e0e0e0;
  overflow-y: auto;
}

.window {
  background-color: var(--darkest);
  margin-top: auto;
  margin-bottom: auto;
  height: calc(100vh - 10px);
  max-height: 600px;
  width: 700px;
  padding: 0;
  border-radius: var(--round-border-radius);
  //   border: 0.2px inset rgba(255, 255, 255, 0.82);
  display: flex;
  flex-direction: column;
  animation: pop-fade 0.25s 1 forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
  //   box-shadow: 0 0 10px 5px var(--dark);
  //   box-shadow: 10px 10px 5px 0px var(--darkest);
  //   -webkit-box-shadow: 10px 10px 5px 0px var(--darkest);
  //   -moz-box-shadow: 10px 10px 5px 0px var(--darkest);

  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px -1px 228px 1px rgba(10, 198, 204, 0.59);
  -webkit-box-shadow: 10px -1px 228px 1px rgba(9, 216, 178, 0.59);
  -moz-box-shadow: 10px -1px 228px 1px rgba(23, 207, 174, 0.59);
}

@keyframes pop-fade {
  0% {
    opacity: 0.2;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.titleBar {
  position: relative;
  background-color: var(--dark);
  border-radius: var(--round-border-radius) var(--round-border-radius) 0 0;
  display: flex;
  flex: 0 0;
  align-items: center;
  color: var(--muted);
  font-size: var(--font-size-header);
  font-family: var(--font-normal);
  font-weight: 900;
  padding: 7px 10px;
  user-select: none;
  -webkit-user-select: none;
}

.dotHolder {
  display: flex;
}

@mixin dot($dotColor) {
  content: "";
  background-color: $dotColor;
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;
  margin: 5px;
}

$dots: (
  "1": var(--red),
  "2": var(--yellow),
  "3": var(--green),
);

@each $x, $color in $dots {
  .dot:nth-of-type(#{$x}) {
    @include dot($dotColor: $color);
  }
}

.titleHeader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  i {
    font-size: 12px;
    margin-right: 5px;
  }
}

.mainContent {
  padding: 20px;
  flex: 1 1;
  overflow: hidden scroll;

  font-family: var(--font-code);
  font-size: var(--font-size-regular);
  color: var(--light);
  line-height: 1.4em;

  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  transition: scrollbar-color 0.25s;
  transition-delay: 0.75s;

  &:hover {
    scrollbar-color: var(--scrollbar-color-thumb) transparent;
    transition-delay: 0s;
    transition-duration: 0;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 100%;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color-thumb);
    border-radius: 0.5em;
  }

  i {
    font-size: var(--font-size-icon);
  }
}

.promptPrefix span:nth-of-type(1) {
  color: var(--blue);
}

.promptPrefix span:nth-of-type(2) {
  color: var(--light-yellow);
}

.validCommand {
  color: var(--green) !important;
}

.invalidCommand {
  color: var(--red) !important;
}

/* Responsive code */
@media only screen and (max-width: 700px), (max-height: 300px) {
  .window {
    width: 100%;
    height: 100%;
    max-height: unset;
    left: 0;
    top: 0;
  }
  .mainContent {
    padding: 10px;
  }
}

/* Blog Navigation */
.blogNav {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.blogCta {
  background-color: transparent;
  color: var(--light);
  border: 1px solid var(--light);
  padding: 8px 16px;
  border-radius: 4px;
  font-family: var(--font-normal);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: var(--light);
    color: var(--darkest);
  }
}

/* Blog page styling */
.blogContainer {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: "Inter", sans-serif;
  background-color: #0e0e0e;
  min-height: 100vh;
  color: #e0e0e0;
  box-sizing: border-box;
}

.blogHeader {
  margin-bottom: 40px;
  background-color: #161616;
  padding: 24px;
  border-radius: 8px;
  color: white;

  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 15px;
    transition: color 0.2s;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.blogTitle {
  font-size: 32px;
  font-weight: normal;
  margin: 0 0 8px 0;
  color: white;
}

.blogDate {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin: 0;
}

.blogPostsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.blogPostsTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: "Inter", sans-serif;

  .headerRow {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;

    th {
      text-align: left;
      font-weight: normal;
      color: #999;
      font-size: 14px;
      padding: 10px 0;
    }
  }

  td {
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.dateColumn {
      width: 80px;
      color: #999;
      font-size: 14px;
    }

    &.titleColumn a {
      color: #e0e0e0;
      text-decoration: none;
      font-size: 16px;

      &:hover {
        color: #3291ff;
      }
    }

    &.viewsColumn {
      text-align: right;
      color: #999;
      font-size: 14px;
    }
  }
}

.blogPostItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:hover h3 {
    color: #0070f3;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    flex: 1;
  }

  h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
    font-weight: normal;
    color: #111;
    transition: color 0.2s;
  }

  .postViews {
    color: #666;
    font-size: 14px;
    text-align: right;
    white-space: nowrap;
    margin-left: 20px;
  }
}

/* Markdown content styling */
.markdownContent {
  color: #e0e0e0;
  line-height: 1.7;
  font-family: "Inter", sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2em;
    margin-bottom: 0.5em;
    color: #ffffff;
    font-weight: 500;
  }

  h1 {
    font-size: 1.8rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 0.3rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  p {
    margin: 1.2rem 0;
    color: #e0e0e0;
  }

  a {
    color: #3291ff;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid #3291ff;
    transition: all 0.2s ease;

    &:hover {
      color: #529dff;
      border-bottom-color: #529dff;
    }
  }

  code:not([class*="language-"]) {
    background-color: #222;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: "JetBrains Mono", monospace;
    font-size: 0.9em;
    color: #e0e0e0;
  }

  pre.code-block {
    position: relative;
    background-color: #1a1a1a !important;
    padding: 1.5rem 1rem 1rem;
    margin: 1.5rem 0;
    border-radius: 6px;
    overflow-x: auto;
    max-width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;

    .code-header {
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px 8px;
      font-size: 11px;
      color: #999;
      background-color: rgba(30, 30, 30, 0.7);
      border-radius: 0 6px 0 6px;
      z-index: 1;
    }

    code {
      background-color: transparent !important;
      padding: 0;
      color: #e0e0e0;
      font-family: "JetBrains Mono", monospace;
      display: block;
      line-height: 1.6;
      font-size: 0.85em;
      tab-size: 2;
      white-space: pre !important;
      word-break: normal;
      word-wrap: normal;
      overflow-x: auto;
    }
  }

  blockquote {
    border-left: 4px solid #3291ff;
    margin: 1.5rem 0;
    padding: 0.5rem 0 0.5rem 1rem;
    color: #bbbbbb;
    background-color: #161616;
  }

  ul,
  ol {
    margin: 1rem 0;
    padding-left: 1.5rem;
    color: #e0e0e0;
  }

  li {
    margin: 0.5rem 0;
    color: #e0e0e0;
  }

  img {
    max-width: 100%;
    border-radius: 6px;
    margin: 1.5rem 0;
  }

  /* Twitter/X.com embeds */
  .twitter-embed {
    margin: 2rem 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%231DA1F2'%3E%3Cpath d='M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 10;
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
    }

    .twitter-tweet {
      margin: 0 auto;

      /* Fallback styling if Twitter script doesn't load */
      padding: 20px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      background-color: #15202b;
      max-width: 550px;
      margin: 1rem auto;

      a {
        display: block;
        color: #1da1f2;
        text-decoration: none;
        border-bottom: none;
        padding: 8px 12px;
        margin-top: 8px;
        border-radius: 30px;
        text-align: center;
        transition: background-color 0.2s;
        font-weight: 500;

        &:hover {
          background-color: rgba(29, 161, 242, 0.1);
        }

        &::before {
          content: "View on Twitter/X";
          margin-right: 4px;
        }
      }
    }
  }

  /* Image styling */
  .markdown-image {
    margin: 2rem 0;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    }

    figcaption {
      margin-top: 0.75rem;
      font-size: 0.9rem;
      color: #999;
      font-style: italic;
    }
  }

  /* Tweet component styling in different contexts */
  ul,
  ol {
    + li a[href*="twitter.com/"],
    + li a[href*="x.com/"] {
      display: inline-block;
      margin-top: 0.5rem;
      word-break: break-word;
    }
  }

  a[href*="twitter.com/"],
  a[href*="x.com/"] {
    color: #1da1f2;
    word-break: break-word;
  }
}

/* Visual indicator for Twitter links in bullet points */
li a[href*="twitter.com/"]::before,
li a[href*="x.com/"]::before {
  content: "🔗 ";
  margin-right: 4px;
}

.blogFooter {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .authorName {
    font-size: 14px;
    color: #999;
    margin-bottom: 1rem;
  }

  .socialLinks {
    display: flex;
    gap: 1rem;

    a {
      color: #999;
      font-size: 1.2rem;
      transition: color 0.2s ease;

      &:hover {
        color: #3291ff;
      }
    }
  }
}

/* Syntax highlighting tokens */
:global {
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #6a9955 !important;
  }

  .token.punctuation {
    color: #d4d4d4 !important;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #b5cea8 !important;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #ce9178 !important;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #d4d4d4 !important;
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #569cd6 !important;
  }

  .token.function,
  .token.class-name {
    color: #dcdcaa !important;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #d16969 !important;
  }
}

/* Twitter embed specific styling */
.tweetWrapper {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  min-height: 200px;
  position: relative;
}

.twitterPlaceholder {
  padding: 1rem;
  background-color: #15202b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: #1da1f2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  min-width: 300px;
  max-width: 550px;
  margin: 0 auto;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

/* Tweet component styling */
.tweetContainer {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tweetEmbed {
  width: 100%;
  max-width: 550px;
  background-color: #191919;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.tweetCaption {
  width: 100%;
  max-width: 550px;
  font-size: 0.9rem;
  color: #999;
  margin-top: 8px;
  text-align: center;
  font-style: italic;

  a {
    color: #3291ff;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom-color: #3291ff;
    }
  }
}

/* MDX styling */
.mdxWrapper {
  padding: 1rem 0;
  line-height: 1.7;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #333;
  }

  h3 {
    font-size: 1.5rem;
  }

  ul,
  ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  pre {
    margin: 1.5rem 0;
    padding: 1rem;
    background-color: #1a1a1a;
    border-radius: 4px;
    overflow-x: auto;
  }

  code {
    font-family: "Fira Code", monospace;
    background-color: #1a1a1a;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-size: 0.9em;
  }

  pre code {
    padding: 0;
    background-color: transparent;
  }

  a {
    color: #3291ff;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom-color: #3291ff;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
}

/* Twitter link styling */
.twitter-link {
  position: relative;
  display: inline-block;
  color: #1da1f2 !important;
  word-break: break-word;

  &::before {
    content: "🐦";
    margin-right: 4px;
  }
}

/* Horizontal tweet testimonials */
.tweetTestimonials {
  display: flex;
  overflow-x: auto;
  padding: 1rem 0;
  gap: 1.5rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
  }

  .tweetContainer {
    min-width: 300px;
    max-width: 350px;
    margin: 0;
    flex-shrink: 0;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }
  }
}

.tweetSkeleton {
  background-color: rgba(25, 25, 25, 0.05);
  border-radius: 12px;
  padding: 1rem;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "Loading tweet...";
    color: #888;
    font-style: italic;
  }
}
